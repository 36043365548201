<script setup>
import BonusPage from './Partials/BonusPage.vue';
import Title from './Partials/Title.vue';
import Subtitle from './Partials/Subtitle.vue';
import { ref } from 'vue';
const displaying = ref('SignupForm');
</script>

<template>
    <BonusPage v-model:displaying="displaying" tag="signup-bonus">
        <div v-if="displaying == 'SignupForm'">
            <Title
                :text="
                    $t(
                        `<strong>Sign up now</strong> for a chance to win  <span class=\&quot;inline-block font-bold\&quot;>€ 1000</span> in Bitcoins!`
                    )
                "
            />
            <Subtitle>
                {{
                    $t(
                        'Fill in your details below and who knows you might be the lucky winner from all the registrations! In addition, stay informed about the latest developments of Flex@ble!'
                    )
                }}
            </Subtitle>
        </div>
    </BonusPage>
</template>
